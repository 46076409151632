.menu-size-increase {
    font-weight: 700;
    font-size: 0.8rem;
}

.color-call {
    color: #ffd63e;
    position: relative;
    top: 3px;
    text-align: right;
    font-size: 0.8rem;
}
