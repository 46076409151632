.info h4 {
    color: #ffd63e;
    font-weight: 600;
}
info p {
    font-size: 12px;
    margin: 15px 0px;
    line-height: 20px;
}


.footer-section {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.footer-section li{
    list-style: none;
    padding: 0px;
    margin: 0px;
    display: inline-block;
    font-size: 14px;
    margin: 0px 5px;
    text-decoration: underline;
}

.contact-info strong {
    font-weight: 500;
}

.info  ul {
    margin: 0px;
    padding: 0px;
}

.info  ul li{
    list-style: none;
    font-size: 13px;
    display: block;
    margin: 10px 5px;
}


.info  ul li a {
    text-decoration: none;
    color: #fff;
}