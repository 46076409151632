.ionic-header {
    text-align: center;
    background-image: url('../../public/assets/sliders/slider-bg1.jpg');
    background-position: center;
    background-size: cover;
    width: 100%;
    padding: 20px;
    display: flex;
    height: 380px;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    position: relative;
}

.mr-bikes-logo {
    width: 200px;
    object-fit: contain;
    margin: 30px 0px;
}

.caption {
    text-align: center;
    margin-bottom: 40px;
}

.caption h1 {
    color: #ffd63e;
    font-size: 2.2rem;
    font-weight: 700;
    padding: 10px;
    text-transform: uppercase;
}

.caption address {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0px;
    text-transform: capitalize;
}


@media screen and (max-width: 768px) {
    .caption h1 {
        font-size: 1.5rem;
    }
    .mr-bikes-logo {
        width: 190px;
        margin: 0px 0px;
        position: relative;
        top: -100px;
    }

    .caption address {
        font-size: 1.2rem;
    }
}

.top-header {
    position: absolute;
    z-index: 999;
    top: 0;
    display: flex;
    left: 0;
    right: 0;
    justify-content: space-between;
    background: #00000075;
    border-bottom: 1px solid #ffc40970;
    align-items: center;
    color: #ffd63e;
}

.top-header div {
    padding: 8px 17px;
    font-size: 18px;
    display: flex;
    border-right: 1px solid #ffc40970;
    justify-content: center;
}